<template>
  <app-panel type="panel"
             class="cart-modal"
             event-name="openCart"
             :title="$t('cart')"
             ref="cartPanel"
             @close="close"
             @open="open">




    <div class="cart-container d-flex align-items-center justify-content-center" v-if="isCartLoading">
      <ui-progress-circular color="black"></ui-progress-circular>
    </div>

    <div class="cart-container" v-if="!isCartLoading">
      <ui-alert type="error" id="cart_error" class="mb-0" v-if="error" @dismiss="error = false">{{error}}</ui-alert>


      <app-announcement position="cart"></app-announcement>

      <div class="cart-empty" v-if="cart.OrderProducts.length === 0">
        <i class="material-icons notranslate">receipt_long</i>
        <strong>{{$t('cart_empty')}}</strong>
        <span>{{$t('cart_empty_text')}}</span>
      </div>

      <div class="cart-content" v-if="cart.OrderProducts.length !== 0">
        <div class="cart-products">
          <div class="cart-product-item" v-if="cart.OrderProducts.length !== 0" @click="openProduct(product)" :class="{'cart-product-item-unavailable': product.product.avaible === false, 'cart-product-error': product.error}" v-for="product in cart.OrderProducts">
            <div class="quantity"><span>{{product.quantity}}</span></div>
            <div class="infos">
              <span>{{product.product.name}}</span>
              <small v-html="product.option"></small>
              <small class="comment" v-if="product.comment">{{ $t('cart_comment_exist') }}</small>
            </div>

            <div class="availability" v-if="product.categoryAvailability && product.error">
              <small>{{product.categoryAvailability.text}} uniquement</small>
            </div>

            <div class="price">
              <span v-if="product.product.avaible === true">{{product.price.price}}</span>
              <span v-else class="price-unavailable">Indisponible</span>

              <i class="material-icons notranslate" @click.stop="removeCart({productId:product.product.id, tid:product.optionIdentifier})">delete</i>
            </div>
          </div>
        </div>

        <app-cart-meta v-for="meta in cart.cartMeta" :meta="meta"></app-cart-meta>

        <div class="cart-comment-row" v-if="this.cart.type !== 'on_site'" @click="$root.$emit('openCartComment')">
          <span v-if="cart.comment" class="comment">{{ cart.comment }}</span>
          <span v-if="!cart.comment">{{ $t('add_cart_comment') }}</span>
        </div>


        <app-discount-code v-if="cart.type !== 'on_site'"></app-discount-code>

        <div class="cart-prices" v-if="this.cart.type === 'delivery'">

          <div class="loading" v-if="isCartPriceLoading">
             <ui-progress-circular></ui-progress-circular>
          </div>

          <div class="price-row" id="subtotal" :class="{'text-danger mb-0': errors.some(e => e.type === 'subtotal') && minProductValue}">
            <span>{{ $t('cart_price_sub_total') }}</span>
            <span>{{cart.values.totalProduct.price}}</span>
          </div>
          <div class="mb-3 mt-1 text-danger" style="line-height: 1" v-if="errors.some(e => e.type === 'subtotal') && minProductValue">
            <small>{{errors.find(e => e.type === 'subtotal').message}}</small>
          </div>


          <template v-if="needUnlock">

            <div class="unlock-long-distance">
              <div class="description">{{$t('cart_small_order_unlock_description', {price: cart.values.merchantUnlockSmallOrderPrice.price})}}</div>
              <div class="actions">
                <button @click="$router.push({name:'merchant', params:{merchantSlug:$route.params.merchantSlug}})">
                  <i class="material-icons notranslate">add_shopping_cart</i>
                  <span>{{$t('cart_small_order_unlock_add_product')}}</span>
                </button>
                <button  @click="unlock()">
                  <i class="material-icons notranslate">login</i>
                  <span>{{$t('cart_small_order_unlock_unlock', {price:cart.values.merchantSmallOrderSupplement.price})}}</span>
                </button>
              </div>
            </div>

          </template>
          <template v-else>

            <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.type !== 'delivery'">
              <button v-if="discount.dismissible" class="discount-remove-button" @click="removeDiscount(discount.discountId)"><i class="material-icons notranslate">close</i></button>
              <span class="discount-name">{{discount.message}}</span>
              <span v-if="discount.value>0">-{{discount.value.price}}</span>
            </div>

            <div class="price-row" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && !cart.cartDiscounts.find(d => d.type === 'delivery')">
              <span v-if="cart.values.deliveryFees.value">{{ $t('cart_price_delivery')}}<b class="pl-1">({{cart.cartDelivery.distance.distance}})</b> <i class="material-icons notranslate">info</i></span>
              <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
              <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_price_delivery_free') }}<b class="pl-1">({{cart.cartDelivery.distance.distance}})</b><i class="material-icons notranslate">info</i></span>
              <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
            </div>
            <div class="price-row discount price-row-cursor" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && cart.cartDelivery.addressDefined && cart.cartDiscounts.find(d => d.type === 'delivery')">
              <span v-if="cart.values.deliveryFees.value">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }} <i class="material-icons notranslate">info</i></span>
              <span v-if="cart.values.deliveryFees.value">{{cart.values.deliveryFees.price}}</span>
              <span v-if="cart.values.deliveryFees.value === 0">{{ cart.cartDiscounts.find(d => d.type === 'delivery').message }}<i class="material-icons notranslate">info</i></span>
              <span v-if="cart.values.deliveryFees.value === 0">{{ $t('cart_gifted') }}</span>
            </div>

            <div class="price-row text-right" v-if="cart.cartDelivery && cart.cartDelivery.deliveryAvaible === true && !cart.cartDelivery.addressDefined">
              <span>{{ $t('cart_price_delivery')}}</span>
              <small class="text-danger" style="cursor:pointer;" @click.prevent="$root.$emit('openAddressList')">{{$t('cart_set_address')}}</small>
            </div>
            <div class="price-row" @click="$root.$emit('openDeliveryInfo')" v-if="cart.cartDelivery && !cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
              <span>{{ $t('cart_price_delivery') }}</span>
              <span>--</span>
            </div>
            <div class="price-row" ref="addressError" v-if="cart.cartDelivery && cart.cartDelivery.address && cart.cartDelivery.deliveryAvaible === false">
              <span>{{ $t('cart_price_delivery') }}</span>
              <span><i class="material-icons notranslate error">error_outline</i></span>
            </div>
            <div class="price-row price-row-light" v-if="cart.values.merchantLongDistanceSupplement && cart.values.merchantLongDistanceSupplement.value !== 0">
              <span>+ {{ $t('cart_price_long_distance_supplement') }}</span>
              <span>{{cart.values.merchantLongDistanceSupplement.price}}</span>
            </div>
            <div class="price-row price-row-light" v-if="cart.values.merchantSmallOrderSupplement && cart.values.merchantSmallOrderSupplement.value !== 0">
              <span>+ {{ $t('cart_price_small_order_supplement') }}</span>
              <span>{{cart.values.merchantSmallOrderSupplement.price}}</span>
            </div>

            <div class="price-row" @click="$root.$emit('openPlatformFeeInfo')">
              <span>{{ $t('cart_price_platform_fee') }} <i class="material-icons notranslate">info</i></span>
              <span v-if="cart.values.platformFees.value">{{cart.values.platformFees.price}}</span>
              <span v-if="cart.values.platformFees.value === 0">{{ $t('cart_gifted') }}</span>
            </div>
            <div class="price-row discount" v-if="cart.values.discount.value !== 0">
              <span>{{ $t('receipt_discount') }}</span>
              <span v-if="cart.values.discount.value">-{{cart.values.discount.price}}</span>
              <span v-if="cart.values.discount.value === 0">{{ $t('cart_gifted') }}</span>
            </div>
            <div class="price-row total">
              <span>{{ $t('cart_price_total') }}</span>
              <span>{{cart.values.total.price}}</span>
            </div>
          </template>


          <div v-if="cart.values.cashback && cart.values.cashback.value>0" style="padding: 0 15px; margin: 0 -15px">
            <div class="cashback-container" @click="openCashbackModal">
              <img src="https://delicity.b-cdn.net/public/assets/party_face.png">
              <div class="cashback-content">
                <b style="margin-bottom: 2px">Vous gagnez un cashback de {{cart.values.cashback.value/100}}€</b>
                <span>Valable sur votre prochaine commande dans cet établissement</span>
              </div>
              <div>
                <i class="material-icons" style="font-size: 16px">info_outline</i>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-prices" v-if="this.cart.type === 'on_site_counter' || this.cart.type === 'pickup'">
          <div class="loading" v-if="isCartPriceLoading">
            <ui-progress-circular></ui-progress-circular>
          </div>

          <div class="price-row total" v-if="cart.cartDiscounts.length > 0">
            <span>{{ $t('cart_price_sub_total') }}</span>
            <span>{{cart.values.totalProduct.price}}</span>
          </div>
          <div class="price-row discount" v-for="discount in cart.cartDiscounts" v-if="discount.value.value !== 0">
            <button v-if="discount.dismissible" class="discount-remove-button" @click="removeDiscount(discount.discountId)"><i class="material-icons notranslate">close</i></button>
            <span class="discount-name">{{discount.message}}</span>
            <span>-{{discount.value.price}}</span>
          </div>



          <div class="price-row total">
            <span>{{ $t('cart_price_total') }}</span>
            <span>{{cart.values.total.price}}</span>
          </div>

          <div v-if="cart.values.cashback && cart.values.cashback.value>0" style="padding: 0 15px; margin: 0 -15px">
            <div class="cashback-container" @click="openCashbackModal">
              <img src="https://delicity.b-cdn.net/public/assets/party_face.png">
              <div class="cashback-content">
                <b style="margin-bottom: 2px">Vous gagnez un cashback de {{cart.values.cashback.value/100}}€</b>
                <span>Valable sur votre prochaine commande dans cet établissement</span>
              </div>
              <div>
                <i class="material-icons" style="font-size: 16px">info_outline</i>
              </div>
            </div>
          </div>
        </div>




        <div class="cart-details" :class="{'pt-4':cart.type === 'on_site'}">
          <div class="details-title">
            <span>{{$t('cart_details')}}</span>
          </div>

          <div class="details-row" v-if="cart.type">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined" v-if="cart.type === 'delivery'">moped</i>
              <i class="material-icons notranslate-outlined" v-if="cart.type === 'pickup'">directions_run</i>
              <i class="material-icons notranslate-outlined" v-if="cart.type === 'on_site_counter'">storefront</i>
            </div>
            <select name="" class="detail-select" v-model="cart.type" @change="changeType">
              <option value="delivery" :disabled="!cart.deliveryAvailable" v-if="merchant.options.deliveryEnabled">{{$t('type_delivery')}}</option>
              <option value="pickup" v-if="merchant.options.pickupEnabled">{{$t('type_pickup')}}</option>
              <option value="on_site_counter" v-if="merchant.options.onsiteCounterEnabled">{{$t('type_on_site_counter')}}</option>
            </select>
          </div>

          <div class="details-row" @click="$root.$emit('openOrderTime')" v-if="cart.type === 'delivery'">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined">timer</i>
            </div>
            <div class="detail-info">
              <span v-if="cart.laterOrder && !cart.laterOrder.today">{{ $t('later_at', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour, max: cart.laterOrder.dateHourMax})}}</span>
              <span v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at', {hour: cart.laterOrder.dateHour, max: cart.laterOrder.dateHourMax})}}</span>
              <span v-if="!cart.laterOrder">{{$t('cart_delivery_duration', {min: cart.cartDelivery.duration.minDuration, max: cart.cartDelivery.duration.maxDuration})}}</span>
            </div>
            <div class="detail-update">
              <span>Modifier</span>
            </div>
          </div>

          <div class="details-row" v-if="cart.type === 'pickup'" @click="$root.$emit('openOrderTime')">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined">timer</i>
            </div>
            <div class="detail-info">
              <span v-if="cart.laterOrder && !cart.laterOrder.today">{{$t('later_at_precise', {date:cart.laterOrder.descriptiveDate, hour: cart.laterOrder.dateHour})}}</span>
              <span v-if="cart.laterOrder && cart.laterOrder.today">{{$t('later_today_at_precise', {hour: cart.laterOrder.dateHour})}}</span>
              <span v-if="!cart.laterOrder">{{$t('cart_preparation_duration', {duration:merchant.estimatedPreparationTime})}}</span>
            </div>
            <div class="detail-update">
              <span>Modifier</span>
            </div>
          </div>

          <div class="details-row" :class="{'details-row-error': cart.cartDelivery && !cart.cartDelivery.address || cart.cartDelivery && !cart.cartDelivery.deliveryAvaible}" @click="openAddress()" ref="addressBox" data-test-id="address-cart" v-if="cart.type === 'delivery'">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined">place</i>
            </div>
            <div class="detail-info" v-if="cart.cartDelivery && cart.cartDelivery.address">
              <strong v-if="cart.cartDelivery.address.name">{{cart.cartDelivery.address.name}}</strong>
              <span>{{cart.cartDelivery.address.address}}</span>
              <span>{{cart.cartDelivery.address.zip}}, {{cart.cartDelivery.address.city}}</span>
              <small class="text-danger mt-2" v-if="cart.cartDelivery && !cart.cartDelivery.deliveryAvaible">{{$t('cart_delivery_error_wrong_location')}}</small>
            </div>
            <div class="detail-info" v-if="cart.cartDelivery && !cart.cartDelivery.address">
              {{ $t('order_config_set_address') }}
            </div>
            <div class="detail-update">
              <span>Modifier</span>
            </div>
          </div>



          <div class="details-row" v-if="cart.user && cart.type !== 'on_site'" :class="{'details-row-error':!user.phone && errors.some(e => e.type === 'user')}" @click="$root.$emit('openOnboarding')">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined">account_circle</i>
            </div>
            <div class="detail-info">
              <span>{{cart.user.username}}</span>
              <small v-if="user.phone">{{cart.user.parsedPhone}}</small>
              <small class="text-danger" v-else-if="!user.phone && errors.some(e => e.type === 'user')">Veuillez Renseigner un numéro de téléphone.</small>
            </div>
            <div class="detail-update">
              <span>Modifier</span>
            </div>
          </div>


          <div class="details-row details-row-error" v-if="!cart.user" @click="$root.$emit('openAuth')">
            <div class="detail-icon">
              <i class="material-icons notranslate-outlined">account_circle</i>
            </div>
            <div class="detail-info">
              <span>Indiquez vos coordonnées</span>
            </div>
            <div class="detail-update">
              <span>Modifier</span>
            </div>
          </div>


          <div class="checkbox-usage" v-if="user">
            <ui-checkbox v-model="conditions" id="condition">
              <span class="animate__animated" :class="{'animate__shakeX': conditionErrorAnimation, 'text-danger': errors.some(e => e.type === 'conditions') && !conditions}">
                J'accepte les <a target="_blank" href="https://pro.delicity.com/cgv/">conditions générales de vente</a>
              </span>
              <i class="material-icons notranslate text-danger" v-if="conditionError">error_outline</i>
            </ui-checkbox>
          </div>
        </div>

      </div>
      <div class="w-100 d-flex justify-content-center mt-5">
         <a href="https://delicity.com" target="_blank"><img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" style="height: 25px"></a>
      </div>

      <div class="panel-bottom"></div>
    </div>


    <div slot="footer">
      <div class="modal-fixed-bottom-button" v-if="cart.OrderProducts.length !== 0 && this.cart.type !== 'on_site'">
        <ui-button size="large"  :disabled="needUnlock" v-if="merchant.shifts.open && merchant.activated || cart.laterOrder" color="green" :loading="isLoading" @click="order" class="cart-button">{{ $t('cart_order_btn') }}</ui-button>
        <ui-button size="large" v-if="!merchant.shifts.open && merchant.activated && !cart.laterOrder" color="green" :loading="isLoading" disabled class="cart-button">{{ $t('cart_order_btn_closed') }}</ui-button>
        <ui-button size="large" v-if="!merchant.activated" color="green" :loading="isLoading" disabled class="cart-button">{{ $t('no_avaible') }}</ui-button>
      </div>
      <div class="modal-fixed-bottom-button" v-if="cart.OrderProducts.length !== 0 && this.cart.type === 'on_site'">
        <ui-button size="large" color="default" disabled class="cart-button">{{ $t('on_site_payment') }}</ui-button>
      </div>
    </div>

    <ui-modal ref="cashback" v-if="cart.values.cashback" v-bind="$attrs" transition="slide-top" remove-header class="panel-base">
      <div style="height: 100%; display: flex; flex-direction: column">
           <ui-toolbar class="nav" nav-icon="west" :remove-nav-icon="true" :raised="false">
        <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
          Cashback
          <ui-icon-button
              color="black"
              class="card-button"
              size="large"
              icon="close"
              type="secondary"
              @click="closeCashback"
          ></ui-icon-button>
        </div>
      </ui-toolbar>
      <div style="display: flex; flex-direction: column; line-height: 1.3; justify-content: center; align-items: center; padding: 40px 15px; text-align: center">
        <img src="https://delicity.b-cdn.net/public/assets/party_face.png" style="width: 70px; margin-bottom: 20px;">
        <span style="font-size: 1.5em; font-weight: 600; margin-bottom: 10px;">En validant cette commande, vous gagnez {{ cart.values.cashback.price }} de cashback</span>
        <span style="font-size: 1.1em; color: #666">{{merchant.name}} vous offre une réduction de {{ cart.values.cashback.price }} valable sur votre prochaine commande dans cet établissement. <br>Vous recevrez le code promo directement dès la validation de la commande</span>
      </div>
      <div style="padding: 15px; position:fixed; bottom:0; left:0; right:0;">
        <ui-button color="primary" @click="closeCashback" class="w-100 py-4">J'ai compris</ui-button>
      </div>
      </div>
    </ui-modal>
  </app-panel>



</template>


<style lang="scss">
.cashback-container{
  background-color: #cfedd3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  img{
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
  .cashback-content {
    display: flex;
    flex-direction: column;
    color: #133a18;
    line-height: 1.2;
    flex: 1;
    b{
      display: block;
      padding-bottom: 2px;
    }
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import PanelTemplate from "@/mobileComponents/PanelTemplate";
import 'tippy.js/index.css'
import CartDiscountCode from "@/mobileComponents/Merchant/CartDiscountCode";
import Announcement from "@/mobileComponents/Merchant/Announcement";
import CartMeta from "@/mobileComponents/Merchant/CartMeta";
import {$vfm} from "vue-final-modal";
import EditProduct from "@/components/Modals/Product/EditProduct";

export default {
  components: {
    'app-panel': PanelTemplate,
    'app-discount-code': CartDiscountCode,
    'app-announcement': Announcement,
    'app-cart-meta': CartMeta
  },
  data(){
    return {
      isOpened: false,
      isLoading: false,
      error: false,
      addressToolTip: false,
      conditions: false,
      conditionError: false,
      conditionErrorAnimation: false,
      paymentMethodType: 'card',
      errors: [],
      isUnlock: true
    }
  },
  methods: {
    ...mapActions(['removeCart', 'initPaymentWallets', 'setOrderType', 'openPaymentModalAction', 'removeDiscountCode', 'cartSetErrorByCategory', 'getUser', 'logout', 'setPaymentCardAction', 'loadOrder']),
    close(){
      this.isLoading = false;
      this.isOpened = false;
    },
    changeType(){
      this.setOrderType(this.cart.type);
    },
    open(){
      this.isOpened = true;
    },
    openCashbackModal(){
      this.$refs.cashback.open();
    },
    closeCashback(){
      this.$refs.cashback.close();
    },
    order(){
      this.error = false;
      this.openPaymentModalAction()
          .catch((errors) => {
            this.errors = errors;

            if(this.errors.some(e => e.type === 'auth')){
              this.$root.$emit('openAuth')
            }
            if(this.errors.some(e => e.type === 'subtotal')){
                document.getElementById('subtotal').scrollIntoView({behavior: 'smooth', block: 'center'});
            }
            else if(this.errors.some(e => e.type === 'shift')){
              this.error = this.errors.find(e => e.type === 'shift').message
              this.$nextTick(() => {
                document.getElementById('cart_error').scrollIntoView({behavior: 'smooth', block: 'center'});
              })
            }
            else if(this.errors.some(e => e.type === 'conditions')){
              const el = document.getElementById('condition');
              el.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.conditionErrorAnimation = true;
              setTimeout(() => {
                this.conditionErrorAnimation = false;
              }, 1000);
            }

          })
    },


    openProduct(cartProduct){
      if(cartProduct.selectedValuesId.length !== 0){
        cartProduct.product.options.forEach((o) => {
          o.values.forEach((v) => {
            if(cartProduct.selectedValuesId[v.id] !== undefined){
              v.checked = true;
              v.quantity = cartProduct.selectedValuesId[v.id];
            }
          });
        });
      }
      $vfm.show({component: EditProduct, bind: {cartProduct}})
      window.history.pushState({}, "",location.pathname + '#update');
    },

    openAddress(){
      this.$root.$emit('openAddressList', {transition: 'slide-top'});
    },

    removeDiscount(id){
      this.removeDiscountCode(id);
    },

    unlock(){
      this.isUnlock = true;
      localStorage.setItem(this.merchant.slug + '-unlock-' + this.cart.id, '1');
    }
  },
  computed: {
    ...mapGetters(['cart', 'user', 'merchant', 'orderToken', 'isCartLoading', 'menu', 'isCartPriceLoading']),
    needUnlock(){
      return this.cart
          && this.cart.values.merchantUnlockSmallOrderPrice
          && this.cart.type === 'delivery'
          && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0
          && !this.isUnlock;
    },
    minProductValue(){
      return this.cart.type === 'delivery' && this.merchant.options.minBaseOrder.value > this.cart.values.totalProduct.value;
    },
  },
  watch: {
    conditions(val){
      this.$store.commit('SET_CONDITION_VALIDATION', val);
    },
    cart(){
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
        this.isUnlock = false;
      }
      if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value === 0){
        localStorage.removeItem(this.merchant.slug + '-unlock-' + this.cart.id);
      }
    }
  },
  mounted() {
    this.initPaymentWallets();

    this.$root.$on('3DS-authentication-failed', (e) => {
      if(e && this.$te('stripe_errors.'+e)){
        this.error = this.$t('stripe_errors.'+e)
      }
      else{
        this.error = this.$t('stripe_errors.3d_secure_failed')
      }

      this.$nextTick(() => {
        document.getElementById('cart_error').scrollIntoView({behavior: 'smooth', block: 'center'});
      })
    })

    if(this.$route.query.stripe_error){
      if(this.$te(this.$route.query.stripe_error)){
        this.error = this.$t('stripe_errors.'+this.$route.query.stripe_error);
      }
      else{
        this.error = this.$t('stripe_errors.card_declined')
      }
    }

    if(this.cart.values.merchantUnlockSmallOrderPrice && this.cart.values.merchantUnlockSmallOrderPrice.value !== 0 && (localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id) !== '1' || !localStorage.getItem(this.merchant.slug + '-unlock-' + this.cart.id))){
      this.isUnlock = false;
    }
  }
}
</script>
